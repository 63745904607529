import './App.css';
import Main from './components/Main';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import NavBar from './components/NavBar';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <NavBar/>
      <div className="AppContainer">
        <Main/>
        <About/>
        <Projects/>
        <Contact/>
      </div>
      <Footer/>
    </div>

  );
}

export default App;
