"use client";
import React, { useState, useRef } from "react";
import ProjectCard from "./ProjectCard";
import ProjectTag from "./ProjectTag";


const projectsData = [
  {
    id: 1,
    title: "Portfolio",
    description: "My current portfolio website using HTML, CSS, and REACT.",
    image: "./images/projects/project1.png",
    tag: ["All", "Web"],
    gitUrl: "/",
    previewUrl: "/",
  },
  {
    id: 2,
    title: "Gourmet Restaurant",
    description: "A restaurant website using HTML, CSS, and REACT.",
    image: "/images/projects/project2.png",
    tag: ["All", "Web"],
    gitUrl: "https://github.com/Sereak/Gourmet-Restaurant-Website",
    previewUrl: "http://sereak.website",
  },
  /*
  {
    id: 3,
    title: "E-commerce Website",
    description: "A e-commerce website with phones as the primary product.",
    image: "/images/projects/p3.png",
    tag: ["All", "Web"],
    gitUrl: "/",
    previewUrl: "http://sereak.tech",
  },
  {
    id: 4,
    title: "Food Ordering Application",
    description: "Project 4 description",
    image: "/images/projects/4.png",
    tag: ["All", "Software"],
    gitUrl: "/",
    previewUrl: "/",
  },
  */
];

const Projects = () => {
  const [tag, setTag] = useState("All");
  const ref = useRef(null);
 

  const handleTagChange = (newTag) => {
    setTag(newTag);
  };

  const filteredProjects = projectsData.filter((project) =>
    project.tag.includes(tag)
  );

  const cardVariants = {
    initial: { y: 50, opacity: 0 },
    animate: { y: 0, opacity: 1 },
  };

  return (
    <section id="projects">
      <h2 className="ProjectHeader">
        My <span>Projects</span>
      </h2>
      <div className="ProjectButton">
        <ProjectTag
          onClick={handleTagChange}
          name="All"
          isSelected={tag === "All"}
        />
        <ProjectTag
          onClick={handleTagChange}
          name="Web"
          isSelected={tag === "Web"}
        />
        <ProjectTag
          onClick={handleTagChange}
          name="Software"
          isSelected={tag === "Software"}
        />
      </div>
      <ul ref={ref} className="Project">
        {filteredProjects.map((project, index) => (
            <ProjectCard
              key={project.id}
              title={project.title}
              description={project.description}
              imgUrl={project.image}
              gitUrl={project.gitUrl}
              previewUrl={project.previewUrl}
            />
        ))}
      </ul>
    </section>
  );
};

export default Projects;
