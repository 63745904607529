"use client";
import React, { useTransition, useState } from "react";
import TabButton from "./TabButton";

const TAB_DATA = [
  {
    title: "Languages",
    id: "languages",
    content: (
      <ul className="AboutS-EL">
        <img src= "/images/languages/c.png" width={100}></img>
        <img src="/images/languages/sql.png" width={100}></img>
        <img src="/images/languages/html.png" width={100}></img>
        <img src="/images/languages/css.png" width={100}></img>
        <img src="/images/languages/react.png" width={100}></img>
        <img src="/images/languages/js.png" width={100}></img>
      </ul>
    ),
  },
  {
    title: "Skills",
    id: "skills",
    content: (
      <ul className="AboutS-E">
        <li>Problem Solving</li>
        <li>Collaboration</li>
        <li>Time Management</li>
      </ul>
    ),
  },
  {
    title: "Education",
    id: "education",
    content: (
      <ul className="AboutS-E">
        <li>Davenport University (Bachelor's CIS)</li>
      </ul>
    ),
  },
];

const About = () => {
  const [tab, setTab] = useState("languages");
  const [isPending, startTransition] = useTransition();

  const handleTabChange = (id) => {
    startTransition(() => {
      setTab(id);
    });
  };

  return (
    <section className="AboutSection" id="about">
      <div className="AboutContainer">
        <div className="AboutTextContainer">
          <h2 className="AboutHeader">About <span>Me</span></h2>
          <p className="AboutP">
            I&apos;m a full stack web developer with a passion for creating
            web applications and working with databases. I have experience working with C#, HTML, 
            CSS, JavaScript, React, and SQL. I love to expand my 
            knowledge and skill set.  
          </p>
          <div className="AboutS-EContainer">
            <TabButton
              selectTab={() => handleTabChange("languages")}
              active={tab === "languages"}
            >
              {" "}
              Languages{" "}
            </TabButton>

            <TabButton
              selectTab={() => handleTabChange("skills")}
              active={tab === "skills"}
            >
              {" "}
              Skills{" "}
            </TabButton>
            
            <TabButton
              selectTab={() => handleTabChange("education")}
              active={tab === "education"}
            >
              {" "}
              Education{" "}
            </TabButton>
          </div>
          <div className="Tab">
            {TAB_DATA.find((t) => t.id === tab).content}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;