import React, { useState } from 'react'
import emailjs from '@emailjs/browser';
const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Sereak Hardges',
      message: message,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response);
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

  }

  function Message(){
    var Name = document.getElementById('name');
    var email = document.getElementById('email');
    var msg = document.getElementById('msg');
    const success = document.getElementById('success');
    const danger = document.getElementById('danger');

    if(Name.value === '' || email.value === '' || msg.value === ''){
        danger.style.display = 'block';
    }
    else{
        setTimeout(() => {
            Name.value = '';
            email.value = '';
            msg.value = '';
        }, 2000);

        success.style.display = 'block';
    }


    setTimeout(() => {
        danger.style.display = 'none';
        success.style.display = 'none';
    }, 4000);

}

    return (
      <section id="contact" className="EmailSection">
        <div className="rounded-full h-80 w-80 z-0 blur-lg absolute top-3/4 -left-4 transform -translate-x-1/2 -translate-1/2"></div>
        <div className="z-10">
          <h5 className="EmailHeader">
            Contact <span>Me</span>
          </h5>
          <p className="text-[#ADB7BE] mb-4 max-w-md">
            {" "}
            I&apos;m currently looking for new opportunities, my inbox is always
            open. Whether you have a question or just want to say hi, I&apos;ll
            try my best to get back to you!
          </p>
          <div className="ContactSocials">
            <a href="https://www.linkedin.com/in/sereak-hardges-12b5731b6/">
              <i class='bx bxl-linkedin-square'></i>
            </a>
          </div>
        </div>

      <form onSubmit={handleSubmit} className='EmailForm'>
        <div className="EmailFormContainerSections">
          <label htmlFor="name" className="EmailFormLabels">Name</label>
          <input required id ="name" className="FormBoxes" type="text" placeholder="Name" value={name}onChange={(e) => setName(e.target.value)}/>
        </div>

        <div className="EmailFormContainerSections">
          <label htmlFor="email" className="EmailFormLabels">Email</label>
          <input required id="email" name="email" className="FormBoxes" type="email" placeholder="example@gmail.com"value={email}onChange={(e) => setEmail(e.target.value)}/>
        </div>

        <div className="EmailFormContainerSections">
          <label htmlFor="message" className="EmailFormLabels">Message</label>
          <textarea  required id="msg" className="FormBoxes" placeholder="Let's talk about..." value={message}onChange={(e) => setMessage(e.target.value)}>
          </textarea>
        </div>

        <button className="SubmitButton" type="submit" onClick={Message}>Send Email</button>

        <div class="Message">
                <div class="success" id="success">Your Message Successfully Sent!</div>
                <div class="danger" id="danger">Error: fields can't be empty</div>
        </div>

      </form>
      <script src=""></script>
    </section>
  )
}


export default Contact;