import React from "react";
import { TypeAnimation } from "react-type-animation";

function Main(){
  return(
  <section>
    <div className="MainSection">
      <div className="MainContent">
        <div className="MainHeaderContainer">
          <h1 className="MainHeader">
            <span>Hello, I&apos;m{" "}</span>
            <br></br>
            <TypeAnimation className="AnimationText"sequence={["Sereak", 1000, "A Web Developer", 1000,"A SQL Developer", 1000, ]} wrapper="span" speed={50} repeat={Infinity} />
          </h1>
          <p className="MainP">
            My name is Sereak and this is my business portfolio. 
          </p>
          <div className="ButtonDiv">
            <a href="#contact"className="MainButton">Contact Me</a>
          </div>
        </div>
          
      </div>
    </div>
  </section>
  )
}

export default Main;